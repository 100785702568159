/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getApp = /* GraphQL */ `
  query GetApp($id: ID!) {
    getApp(id: $id) {
      id
      type
      sort
      title
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const listApps = /* GraphQL */ `
  query ListApps(
    $filter: ModelAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        sort
        title
        logo {
          bucket
          region
          key
        }
        link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHr = /* GraphQL */ `
  query GetHr($id: ID!) {
    getHr(id: $id) {
      id
      type
      sort
      title
      descr
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const listHrs = /* GraphQL */ `
  query ListHrs($filter: ModelHrFilterInput, $limit: Int, $nextToken: String) {
    listHrs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        sort
        title
        descr
        logo {
          bucket
          region
          key
        }
        link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRq = /* GraphQL */ `
  query GetRq($id: ID!) {
    getRq(id: $id) {
      id
      type
      sort
      title
      descr
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const listRqs = /* GraphQL */ `
  query ListRqs($filter: ModelRqFilterInput, $limit: Int, $nextToken: String) {
    listRqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        sort
        title
        descr
        logo {
          bucket
          region
          key
        }
        link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTp = /* GraphQL */ `
  query GetTp($id: ID!) {
    getTp(id: $id) {
      id
      type
      sort
      title
      descr
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const listTps = /* GraphQL */ `
  query ListTps($filter: ModelTpFilterInput, $limit: Int, $nextToken: String) {
    listTps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        sort
        title
        descr
        logo {
          bucket
          region
          key
        }
        link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      id
      type
      sort
      title
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        sort
        title
        logo {
          bucket
          region
          key
        }
        link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAllApps = /* GraphQL */ `
  query ListAllApps(
    $sort: ModelIntKeyConditionInput
    $filter: ModelAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllApps(
      type: "APP"
      sort: $sort
      sortDirection: ASC
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sort
        title
        logo {
          bucket
          region
          key
        }
        link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAllHrs = /* GraphQL */ `
  query ListAllHrs(
    $sort: ModelIntKeyConditionInput
    $filter: ModelHrFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllHrs(
      type: "APP"
      sort: $sort
      sortDirection: ASC
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sort
        title
        descr
        logo {
          bucket
          region
          key
        }
        link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAllRqs = /* GraphQL */ `
  query ListAllRqs(
    $sort: ModelIntKeyConditionInput
    $filter: ModelRqFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllRqs(
      type: "APP"
      sort: $sort
      sortDirection: ASC
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sort
        title
        descr
        logo {
          bucket
          region
          key
        }
        link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAllTps = /* GraphQL */ `
  query ListAllTps(
    $sort: ModelIntKeyConditionInput
    $filter: ModelTpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllTps(
      type: "APP"
      sort: $sort
      sortDirection: ASC
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sort
        title
        descr
        logo {
          bucket
          region
          key
        }
        link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAllChannels = /* GraphQL */ `
  query ListAllChannels(
    $sort: ModelIntKeyConditionInput
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllChannels(
      type: "APP"
      sort: $sort
      sortDirection: ASC
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        sort
        title
        logo {
          bucket
          region
          key
        }
        link
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
