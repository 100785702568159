import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import logo from './logo-cardano.png' // relative path to image 

const headLabel = { 
    color: '#ddd',  
    fontSize: '0.95em', 
    fontWeight: "bold",
}

class Logo extends Component {
  render() {
    return (
      <div>
         
          <Typography  guttertop="true" style={headLabel} component="div">
             <img align="center" src={logo} alt={"logo"} width="25" height="25" /> &nbsp;Foundation Launchpad
          </Typography> 


      </div>
    )
  }
}

export default Logo;