import * as React from 'react';
import {
  useListContext,  
  Create,
  Edit,
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  DateInput,
  required
} from "react-admin";


import { AmplifyImageInput, AmplifyImageField, AmplifyFilter} from "react-admin-amplify";

import { Card, CardContent, CardActionArea, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({ 

    // This will override the style of the <img> inside the AmplifyImageField
    image: { 
        height: 150,
        width: 300,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 0,
        marginTop: 0,         
    }  

});

const validateSort = [required()];
const validateTitle = [required()];
const validateLink = [required()];
const validateDescr = [required()];
const validateType = [required()];

const cardlabel = { 
    color: '#274a65', 
    fontSize: '0.7em', 
    fontWeight: "bold",
}

const cardlabelsub = { 
    color: '#777', 
    lineHeight: 1.2,
}

const heading = { 
    margin: 0,
    padding: 0,
    marginBottom: 5,
    marginTop: 0,    
}

const cardStyle = {
    width: 300,
    minHeight: 200,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top',
};

const TpFilter = (props) => (
  <AmplifyFilter {...props}>
    <TextInput source="listAllTps.type" label="Type" alwaysOn resettable />
  </AmplifyFilter>
);

const TpGrid = () => {
    const { ids, data, basePath } = useListContext();
    const classes = useStyles();
    return (
        <div style={{ margin: '1em' }}>
        {ids.map(id =>
            <Card key={id} style={cardStyle}>
                <CardActionArea href={data[id].link} target="_blank">
                    <AmplifyImageField classes={classes} record={data[id]} source="logo" storageOptions={{ level: "public" }} title="Logo" addLabel={false} />   
                    <CardContent>
                      <Typography gutterBottom style={heading}  variant="h5" component="div">
                        <TextField style={cardlabel} record={data[id]} source="title" />
                      </Typography > 
                      <Typography gutterBottom variant="caption" component="div">  
                        <TextField style={cardlabelsub} record={data[id]} source="descr"/>  
                      </Typography >    
                    </CardContent>
                </CardActionArea>
            </Card>
        )}
        </div>
    );
};

export const TpEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" disabled/>
            <NumberInput source="sort" validate={validateSort}/>
            <TextInput source="type" validate={validateType}/>
            <TextInput source="title" validate={validateTitle}/>
            <TextInput source="descr" validate={validateDescr} />
            <TextInput source="link" validate={validateLink}/>
            <AmplifyImageInput source="logo" accept="image/*" multiple={false} storageOptions={{ level: "public" }}/>
            <DateInput source="createdAt" disabled/>
            <DateInput source="updatedAt" disabled/>
        </SimpleForm>
    </Edit>
);


export const TpCreate = (props) => (
  <Create {...props}>
        <SimpleForm redirect="list">
            <NumberInput source="sort" validate={validateSort} />
            <TextInput source="type" initialValue="APP" validate={validateType}/>
            <TextInput source="title" validate={validateTitle} />
            <TextInput source="descr" validate={validateDescr} />
            <TextInput source="link" validate={validateLink}/>
            <AmplifyImageInput source="logo" accept="image/*" multiple={false} storageOptions={{ level: "public" }}/>
        </SimpleForm>
  </Create>
);

export const AdminTpList = (props) => (
    <List title="Tp" {...props} filters={<TpFilter />} perPage={1000} pagination={false} exporter={false} >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <NumberField source="sort" />
            <TextField source="type" />
            <TextField source="title" />
            <TextField source="descr" />
            <TextField source="link" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);

export const TpShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <TextField source="descr" />    
      <AmplifyImageField source="logo" storageOptions={{ level: "public" }} title="Logo" addLabel={false} />
    </SimpleShowLayout>
  </Show>
);

export const TpList = (props) => (
    <List title="Tp" {...props} perPage={1000} pagination={false} exporter={false} >
        <TpGrid />
    </List>
);
