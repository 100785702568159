/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:82cbdee0-6bc5-414c-86ab-4d5387d2087d",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Icv0poT4C",
    "aws_user_pools_web_client_id": "112s78f357evbsvl59ouriauo8",
    "oauth": {
        "domain": "launchpad-accounts-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://www.launchpad.cf-app.org/",
        "redirectSignOut": "https://www.launchpad.cf-app.org/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_appsync_graphqlEndpoint": "https://7qipla6kiver5lseqmlnbk4axe.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "opslaunchpadapp297636cac7c8488299585761837b4122224436-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
