/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createApp = /* GraphQL */ `
  mutation CreateApp(
    $input: CreateAppInput!
    $condition: ModelAppConditionInput
  ) {
    createApp(input: $input, condition: $condition) {
      id
      type
      sort
      title
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const updateApp = /* GraphQL */ `
  mutation UpdateApp(
    $input: UpdateAppInput!
    $condition: ModelAppConditionInput
  ) {
    updateApp(input: $input, condition: $condition) {
      id
      type
      sort
      title
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const deleteApp = /* GraphQL */ `
  mutation DeleteApp(
    $input: DeleteAppInput!
    $condition: ModelAppConditionInput
  ) {
    deleteApp(input: $input, condition: $condition) {
      id
      type
      sort
      title
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const createHr = /* GraphQL */ `
  mutation CreateHr($input: CreateHrInput!, $condition: ModelHrConditionInput) {
    createHr(input: $input, condition: $condition) {
      id
      type
      sort
      title
      descr
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const updateHr = /* GraphQL */ `
  mutation UpdateHr($input: UpdateHrInput!, $condition: ModelHrConditionInput) {
    updateHr(input: $input, condition: $condition) {
      id
      type
      sort
      title
      descr
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const deleteHr = /* GraphQL */ `
  mutation DeleteHr($input: DeleteHrInput!, $condition: ModelHrConditionInput) {
    deleteHr(input: $input, condition: $condition) {
      id
      type
      sort
      title
      descr
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const createRq = /* GraphQL */ `
  mutation CreateRq($input: CreateRqInput!, $condition: ModelRqConditionInput) {
    createRq(input: $input, condition: $condition) {
      id
      type
      sort
      title
      descr
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const updateRq = /* GraphQL */ `
  mutation UpdateRq($input: UpdateRqInput!, $condition: ModelRqConditionInput) {
    updateRq(input: $input, condition: $condition) {
      id
      type
      sort
      title
      descr
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const deleteRq = /* GraphQL */ `
  mutation DeleteRq($input: DeleteRqInput!, $condition: ModelRqConditionInput) {
    deleteRq(input: $input, condition: $condition) {
      id
      type
      sort
      title
      descr
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const createTp = /* GraphQL */ `
  mutation CreateTp($input: CreateTpInput!, $condition: ModelTpConditionInput) {
    createTp(input: $input, condition: $condition) {
      id
      type
      sort
      title
      descr
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const updateTp = /* GraphQL */ `
  mutation UpdateTp($input: UpdateTpInput!, $condition: ModelTpConditionInput) {
    updateTp(input: $input, condition: $condition) {
      id
      type
      sort
      title
      descr
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const deleteTp = /* GraphQL */ `
  mutation DeleteTp($input: DeleteTpInput!, $condition: ModelTpConditionInput) {
    deleteTp(input: $input, condition: $condition) {
      id
      type
      sort
      title
      descr
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const createChannel = /* GraphQL */ `
  mutation CreateChannel(
    $input: CreateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    createChannel(input: $input, condition: $condition) {
      id
      type
      sort
      title
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const updateChannel = /* GraphQL */ `
  mutation UpdateChannel(
    $input: UpdateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    updateChannel(input: $input, condition: $condition) {
      id
      type
      sort
      title
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
export const deleteChannel = /* GraphQL */ `
  mutation DeleteChannel(
    $input: DeleteChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    deleteChannel(input: $input, condition: $condition) {
      id
      type
      sort
      title
      logo {
        bucket
        region
        key
      }
      link
      createdAt
      updatedAt
    }
  }
`;
