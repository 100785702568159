import React from "react";
import { Admin, Resource, defaultTheme } from 'react-admin';
import MyLayout from './MyLayout';
import { createTheme } from '@material-ui/core/styles';

import { AppShow, AdminAppList, AppCreate, AppEdit, AppList } from './components/App';
import { HrShow, AdminHrList, HrCreate, HrEdit, HrList  } from './components/Hr';
import { RqShow, AdminRqList, RqCreate, RqEdit, RqList  } from './components/Rq';
import { TpShow, AdminTpList, TpCreate, TpEdit, TpList  } from './components/Tp';
import { ChannelShow, AdminChannelList, ChannelCreate, ChannelEdit, ChannelList  } from './components/Channel';

import { Amplify } from "@aws-amplify/core";
import { buildAuthProvider, buildDataProvider } from "react-admin-amplify";
import awsExports from "./aws-exports";
import * as mutations from "./graphql/mutations";
import * as queries from "./graphql/queries";

import AppsIcon  from '@material-ui/icons/Apps';
import DocIcon from '@material-ui/icons/Subject';
import CommunityIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import PublicIcon from '@material-ui/icons/Language';
import AssignmentIcon from '@material-ui/icons/Assignment';
import HandIcon from '@material-ui/icons/PanTool';


import MyLoginPage from './MyLoginPage';


Amplify.configure(awsExports); 

const theme = createTheme({
    ...defaultTheme,
    palette: {
        secondary: {
            light: '#6ec6ff',
            main: '#103654',
            dark: '#0069c0',
            contrastText: '#fff',
        },
    },    
    sidebar: {
        width: 240, // The default value is 240
        closedWidth: 55, // The default value is 55
    },
});
  

function App() {
  return (
    <Admin
      layout={MyLayout} 
      loginPage={MyLoginPage} 
      theme={theme} 
      // loginPage={MyLoginPage}
      authProvider={buildAuthProvider({ authGroups: ["team", "admin"] })} 
      dataProvider={buildDataProvider({ queries, mutations }, {storageBucket: awsExports.aws_user_files_s3_bucket, storageRegion: awsExports.aws_user_files_s3_bucket_region})}
    >
      {(permissions) => [
        <Resource name="AllApps" list={permissions.includes("admin") ? AppList : AppList} options={{ label: 'Apps' }} icon={AppsIcon} />,
        <Resource name="apps" show={permissions.includes("admin") ? AppShow : null} list={permissions.includes("admin") ? AdminAppList : null} edit={permissions.includes("admin") ? AppEdit : null} create={permissions.includes("admin") ? AppCreate : null}  options={{ label: 'A-Apps' }} icon={AppsIcon} />,
        <Resource name="AllHrs" list={permissions.includes("admin") ? HrList : HrList} options={{ label: 'Resources' }} icon={DocIcon} />,
        <Resource name="hrs" show={permissions.includes("admin") ? HrShow : null} list={permissions.includes("admin") ? AdminHrList : null} edit={permissions.includes("admin") ? HrEdit : null} create={permissions.includes("admin") ? HrCreate : null}  options={{ label: 'A-Resources' }} icon={DocIcon} />,
        <Resource name="AllRqs" list={permissions.includes("admin") ? RqList : RqList} options={{ label: 'Requests' }} icon={HandIcon} />,
        <Resource name="rqs" show={permissions.includes("admin") ? RqShow : null} list={permissions.includes("admin") ? AdminRqList : null} edit={permissions.includes("admin") ? RqEdit : null} create={permissions.includes("admin") ? RqCreate : null}  options={{ label: 'A-Requests' }} icon={HandIcon} />,   
        <Resource name="AllTps" list={permissions.includes("admin") ? TpList : TpList} options={{ label: 'Templates' }} icon={AssignmentIcon} />,
        <Resource name="tps" show={permissions.includes("admin") ? TpShow : null} list={permissions.includes("admin") ? AdminTpList : null} edit={permissions.includes("admin") ? TpEdit : null} create={permissions.includes("admin") ? TpCreate : null}  options={{ label: 'A-Templates' }} icon={AssignmentIcon} />,             
        <Resource name="AllChannels" list={permissions.includes("admin") ? ChannelList : ChannelList} options={{ label: 'Public' }} icon={PublicIcon} />,
        <Resource name="channels" show={permissions.includes("admin") ? ChannelShow : null} list={permissions.includes("admin") ? AdminChannelList : null} edit={permissions.includes("admin") ? ChannelEdit : null} create={permissions.includes("admin") ? ChannelCreate : null}  options={{ label: 'A-Public' }} icon={PublicIcon} />,
        
      ]}
    </Admin>
  );
}

export default App;


